import React from "react"
import { Container } from "../components/Container"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"

export default ({}) => (
  <Layout
    seo={{
      title: "Niet Gevonden",
      path: "/404",
      description: "Deze pagina is niet gevonden",
    }}
  >
    <section>
      <Container>
        <div style={{ textAlign: "center" }}>
          <p>
            Het lijkt er op dat je een pagina gevonden hebt die niet bestaat.
          </p>
          <Link className="green button" to="/">
            Terug naar de home pagina
          </Link>
        </div>
      </Container>
    </section>
  </Layout>
)
